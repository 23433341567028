import { Injectable } from '@angular/core';
import { GLOBAL } from '../app.globals';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class CommonService {
  constructor(private http: HttpClient,) { }

    public exportExcelWithLayout(name, table, dyncss){

      let style = "<style>"+dyncss+"</style>";

      let uri = 'data:application/vnd.ms-excel;base64,'
          , template = `<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->`+ style +`</head><body><table>{table}</table></body></html>`
          , base64 = function (s) {
              return window.btoa(unescape(encodeURIComponent(s)))
          }
          , format = function (s, c) {
              return s.replace(/{(\w+)}/g, function (m, p) { return c[p]; })
          }
          var ctx = { worksheet: '' || 'Sheet1', table: table.nativeElement.innerHTML, fileName: 'test' }
          //window.location.href = uri + base64(format(template, ctx))
          let link = document.createElement('a');
          link.download = name+'.xls';
          link.href = uri + base64(format(template, ctx));
          link.click();
    }

    public printTable(tableId, dynCss, printConfOpt){
      let printDetails, popupWin: Window;
      let papersize = printConfOpt.PaperSize+" "+printConfOpt.PaperLayout;
      //let papersize = printConfOpt.PaperLayout;
      let zoomlevel = printConfOpt.PaperScalSize+"%";
      printDetails = document.getElementById(tableId).innerHTML;
      popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      popupWin.document.open();
      //let papersize= 'portrait';
      //zoom: `+zoom+`%;

      popupWin.document.write(`
      <!DOCTYPE html>
      <html>
        <head>
        <style>
        @page {
          size: `+papersize+`;
          margin: 0px; 
        }
        @media print{
        body {
          zoom: `+zoomlevel+` !important;
          padding: 2px;
          -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
          color-adjust: exact !important;                 /* Firefox 48 – 96 */
          print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
          }
        }`+dynCss+
        `</style>
        </head>
    <body onload="window.print();">${printDetails}</body>
      </html>`
      );
      popupWin.document.close();
    }

    getPrintOptions(pageCode):Observable<any>{
      const req = {
        "Page_Code": pageCode,
        "RequireType": 1,
        "ActionType": 1,
        "UserID": GLOBAL.USER_ID
      }
      const url = `${GLOBAL.APIS.COMMON_GLOBAL_VAR.PAGE_NPRINT_CONFIG}`;
      // console.log(url);
      return this.http.post<any[]>(url, req).pipe(
        map((response: any) => response));
    }
}
